import { useTheme } from "@mui/material";
import * as React from "react";

const AllIcon = ({ fill, ...props }) => {
  const theme = useTheme();

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75 5.75V6.25C4.75 6.51522 4.85536 6.76957 5.04289 6.95711C5.23043 7.14464 5.48478 7.25 5.75 7.25H18.25C18.5152 7.25 18.7696 7.14464 18.9571 6.95711C19.1446 6.76957 19.25 6.51522 19.25 6.25V5.75C19.25 5.48478 19.1446 5.23043 18.9571 5.04289C18.7696 4.85536 18.5152 4.75 18.25 4.75H5.75C5.48478 4.75 5.23043 4.85536 5.04289 5.04289C4.85536 5.23043 4.75 5.48478 4.75 5.75ZM4.75 11.75V12.25C4.75 12.5152 4.85536 12.7696 5.04289 12.9571C5.23043 13.1446 5.48478 13.25 5.75 13.25H18.25C18.5152 13.25 18.7696 13.1446 18.9571 12.9571C19.1446 12.7696 19.25 12.5152 19.25 12.25V11.75C19.25 11.4848 19.1446 11.2304 18.9571 11.0429C18.7696 10.8554 18.5152 10.75 18.25 10.75H5.75C5.48478 10.75 5.23043 10.8554 5.04289 11.0429C4.85536 11.2304 4.75 11.4848 4.75 11.75ZM4.75 17.75V18.25C4.75 18.5152 4.85536 18.7696 5.04289 18.9571C5.23043 19.1446 5.48478 19.25 5.75 19.25H18.25C18.5152 19.25 18.7696 19.1446 18.9571 18.9571C19.1446 18.7696 19.25 18.5152 19.25 18.25V17.75C19.25 17.4848 19.1446 17.2304 18.9571 17.0429C18.7696 16.8554 18.5152 16.75 18.25 16.75H5.75C5.48478 16.75 5.23043 16.8554 5.04289 17.0429C4.85536 17.2304 4.75 17.4848 4.75 17.75Z"
        stroke={fill || theme.palette.text.primary} // Default stroke color
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AllIcon;
