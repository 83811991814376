export const ipaddressdata = [
  {
    ipaddress: "123.456.789.123",
    customer: "John Doe",
    countries: 23,
    accounts: 23,
    purchase: 8,
    active: 13,
  },
  {
    ipaddress: "523.65.63.321",
    customer: "Jane",
    countries: 40,
    accounts: 16,
    purchase: 6,
    active: 16,
  },
  {
    ipaddress: "192.168.1.1",
    customer: "Alice Johnson",
    countries: 15,
    accounts: 10,
    purchase: 5,
    active: 7,
  },
  {
    ipaddress: "10.0.0.1",
    customer: "Bob Smith",
    countries: 30,
    accounts: 22,
    purchase: 12,
    active: 18,
  },
  {
    ipaddress: "172.16.0.2",
    customer: "Charlie Brown",
    countries: 20,
    accounts: 18,
    purchase: 9,
    active: 14,
  },
  {
    ipaddress: "192.168.100.100",
    customer: "David Williams",
    countries: 12,
    accounts: 8,
    purchase: 4,
    active: 6,
  },
  {
    ipaddress: "172.16.254.1",
    customer: "Emma Davis",
    countries: 25,
    accounts: 20,
    purchase: 10,
    active: 15,
  },
  {
    ipaddress: "203.0.113.0",
    customer: "Frank Miller",
    countries: 28,
    accounts: 24,
    purchase: 11,
    active: 17,
  },
  {
    ipaddress: "198.51.100.0",
    customer: "Grace Wilson",
    countries: 35,
    accounts: 28,
    purchase: 13,
    active: 19,
  },
  {
    ipaddress: "192.0.2.0",
    customer: "Henry Moore",
    countries: 18,
    accounts: 14,
    purchase: 7,
    active: 10,
  },
  {
    ipaddress: "203.0.113.1",
    customer: "Isla Taylor",
    countries: 22,
    accounts: 17,
    purchase: 8,
    active: 12,
  },
  {
    ipaddress: "198.51.100.1",
    customer: "Jack Anderson",
    countries: 27,
    accounts: 23,
    purchase: 9,
    active: 14,
  },
  {
    ipaddress: "192.0.2.1",
    customer: "Karen Thomas",
    countries: 19,
    accounts: 16,
    purchase: 7,
    active: 11,
  },
  {
    ipaddress: "198.51.100.2",
    customer: "Liam Jackson",
    countries: 31,
    accounts: 25,
    purchase: 11,
    active: 18,
  },
  {
    ipaddress: "192.0.2.2",
    customer: "Mia White",
    countries: 24,
    accounts: 19,
    purchase: 9,
    active: 13,
  },
];
