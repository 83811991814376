import React from "react";

const AffiliateIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      style={{ marginRight: "6px" }}
    >
      <path
        d="M 8 8 L 8.01 8 M 8 16 L 8.01 16 M 16 8 L 16.01 8 M 16 16 L 16.01 16 M 13.25 12 C 13.25 12.69 12.69 13.25 12 13.25 C 11.31 13.25 10.75 12.69 10.75 12 C 10.75 11.31 11.31 10.75 12 10.75 C 12.69 10.75 13.25 11.31 13.25 12 Z M 13.25 6 C 13.25 6.69 12.69 7.25 12 7.25 C 11.31 7.25 10.75 6.69 10.75 6 C 10.75 5.31 11.31 4.75 12 4.75 C 12.69 4.75 13.25 5.31 13.25 6 Z M 19.25 12 C 19.25 12.69 18.69 13.25 18 13.25 C 17.31 13.25 16.75 12.69 16.75 12 C 16.75 11.31 17.31 10.75 18 10.75 C 18.69 10.75 19.25 11.31 19.25 12 Z M 7.25 12 C 7.25 12.69 6.69 13.25 6 13.25 C 5.31 13.25 4.75 12.69 4.75 12 C 4.75 11.31 5.31 10.75 6 10.75 C 6.69 10.75 7.25 11.31 7.25 12 Z M 13.25 18 C 13.25 18.69 12.69 19.25 12 19.25 C 11.31 19.25 10.75 18.69 10.75 18 C 10.75 17.31 11.31 16.75 12 16.75 C 12.69 16.75 13.25 17.31 13.25 18 Z"
        fill={color || "transparent"}
        stroke-width="1.5"
        stroke={color || "rgb(138, 150, 166)"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray=""
      ></path>
    </svg>
  );
};

export default AffiliateIcon;
