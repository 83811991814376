import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper, Card, Grid, IconButton, Stack, Tooltip, alpha, useMediaQuery } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { PiWarningDiamondBold } from "react-icons/pi";
import { MdDone } from "react-icons/md";
import Searchbar from "../../../components/ui/Searchbar";
import ExportSection from "../../../components/ui/ExportSection";
import CalendarPopup from "../../../components/ui/CalendarPopup";
import DisplayColumns from "../../../components/ui/DisplayColumns";
import CustomTable from "../../../components/Firm/Orders/Table";
import dayjs from "dayjs";
import FilterPopup from "../../../components/ui/FilterPopup";
import { IoMdTime } from "react-icons/io";
import { FiDownloadCloud } from "react-icons/fi";
import { ipaddressdata } from "../../../static/flaggedaccounts";
import SmallSearchBar from "../../../components/Affiliates/smallSearchBar";

const Items = [
  {
    title: "Total scanned",
    tooltip: "Active accounts that are doing a evaluation, funded are on trial",
    value: "8141",
    key: "active",
  },
  {
    title: "Total flagged IPs",
    tooltip: "All inactive accounts",
    value: "340",
    key: "inactive",
  },
  {
    title: "Total passed",
    tooltip: "All inactive accounts",
    value: "120",
    key: "inactive",
  },
];

const headcells = [
  {
    id: "ipaddress",
    label: "IP-Address",
    default: true,
    getCell: (row) => row.ipaddress,
  },
  {
    id: "customer",
    label: "Customer",
    default: true,
    getCell: (row) => row.customer,
  },
  {
    id: "countries",
    label: "Countries",
    default: true,
    getCell: (row) => row.countries,
  },
  {
    id: "accounts",
    label: "Accounts",
    default: true,
    getCell: (row) => row.accounts,
  },
  {
    id: "purchase",
    label: "Purchase",
    default: true,
    getCell: (row) => row.purchase,
  },
  {
    id: "active",
    label: "Active",
    default: true,
    getCell: (row) => row.active,
  },
  {
    id: "action",
    label: "Action",
    default: true,
    getCell: (row) => (
      <Box
        sx={{
          color: (theme) => theme.palette.color.secondary,
        }}
      >
        <FiDownloadCloud size={18} />
      </Box>
    ),
  },
];

const IpAddress = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [status, setStatus] = useState("");
  const [heads, setHeads] = React.useState(
    headcells.filter((cell) => cell.default).map((cell) => cell.id)
  );
  const [startDate, setStartDate] = useState(dayjs().startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("week"));
  const [rangeValue, setRangeValue] = useState([20, 37]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState([]);
  const [cols, setCols] = useState(headcells);

  const parseRange = (rangeStr) => {
    const [min, max] = rangeStr.split(" - ").map(Number);
    return { min, max };
  };

  const filterData = () => {
    return ipaddressdata.filter((d) => {
      if (d.countries < rangeValue[0] || d.countries > rangeValue[1])
        return false;

      if (selectedAccounts.length > 0) {
        const accountMatch = selectedAccounts.some((range) => {
          const { min, max } = parseRange(range);
          return d.accounts >= min && d.accounts <= max;
        });
        if (!accountMatch) return false;
      }

      if (selectedPurchase.length > 0) {
        const purchaseMatch = selectedPurchase.some((range) => {
          const { min, max } = parseRange(range);
          return d.purchase >= min && d.purchase <= max;
        });
        if (!purchaseMatch) return false;
      }

      return true;
    });
  };
  return (
    <Paper
      sx={{
        px: "12px",
        py: "24px",
        width: "100%",
      }}
    >

      <Stack
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "flex-start" : "space-between"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        gap={2}
      >
        <Box
          sx={{
            ml: "15px",
            display: "flex",
            alignItems: "start",
          }}
        >
          <Typography variant="subtitle1" mb={2}>
            IP-Adresses
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={isSmallScreen ? 1 : 2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            mb: "24px",
            fontSize: "11.5px",
            pr: "24px",
            color: (theme) => theme.palette.color.secondary,
          }}
        >
          <SmallSearchBar />
          <ExportSection />
          <ExportSection isImport={true} />
          <CalendarPopup
            mainStartDate={startDate}
            mainEndDate={endDate}
            setMainStartDate={setStartDate}
            setMainEndDate={setEndDate}
          />
          <DisplayColumns
            columns={cols}
            setColumns={setCols}
            selectedColumns={heads}
            setSelectedColumns={setHeads}
          />
          <FilterPopup
            rangeFilter={{
              label: "Countries",
              ariaLabel: "Countries",
              value: rangeValue,
              onChange: setRangeValue,
            }}
            accordions={[
              {
                title: "Accounts",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedAccounts,
                onChange: setSelectedAccounts,
              },
              {
                title: "Purchase",
                defaultExpanded: true,
                items: ["0 - 10", "10 - 30", "30 - 50"],
                selectedItems: selectedPurchase,
                onChange: setSelectedPurchase,
              },
            ]}
          />
        </Stack>
      </Stack>


      <Grid container spacing={1}>
        {Items.map((item, index) => (
          <Grid
            item
            xs={index === 2 ? 12 : 6} 
            md={4} 
            lg={4}
            spacing={2}
            key={index}
          >
            <Card
              // onClick={() => setStatus(item.key)}
              sx={{
                width: "100%",
                border: (theme) =>
                  `1px solid ${item.key === status
                    ? theme.palette.color.green
                    : theme.palette.color.border
                  }`,
                background: (theme) =>
                  item.key === status
                    ? alpha(theme.palette.color.green, 0.05)
                    : theme.palette.color.bg3,
                borderRadius: "24px",
                overflow: "hidden",
                padding: "24px",
                cursor: "pointer",
                ...(status &&
                  item.key !== status && {
                  opacity: 0.35,
                }),
                ...(item.key === status && {
                  transition: "all 0.4s ease-in",
                }),
              }}
            >
              <Stack spacing={5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="caption"
                    sx={{
                      ...(item.key === status && {
                        color: (theme) => theme.palette.color.green,
                      }),
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Tooltip title={item.tooltip} placement="top">
                    <HelpIcon
                      sx={{
                        fontSize: "15px",
                        cursor: "pointer",
                        color: (theme) =>
                          theme.palette.color[
                          item.key === status ? "green" : "secondary"
                          ],
                      }}
                    />
                  </Tooltip>
                </Stack>

                <Typography
                  variant="h1"
                  sx={{
                    ...(item.key === status && {
                      color: (theme) => theme.palette.color.green,
                    }),
                    fontWeight: "600",
                  }}
                >
                  {item.value}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>

      <CustomTable headcells={cols} rows={filterData()} />
    </Paper>
  );
};

export default IpAddress;
